import Button from '../Button/Button';
import ObLink from '../ObLink/ObLink';
import React from 'react';
import Block from '../../adapters/helpers/Block';
import Icon from '../Icon/Icon';
import Backdrop from '../Backdrop/Backdrop';
import Heading from '../Heading/Heading';
import {ArticleCategoryHeroBlockConstants, ContentConstants} from '../../adapters/helpers/Constants'
import Disclaimer from '../Disclaimer/Disclaimer';
import Image from '../Image/Image';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { sanitize } from 'isomorphic-dompurify';

function ArticleCategoryHeroBlock(props) {
    const block = new Block(props);

    const className = block.getFieldValue(ArticleCategoryHeroBlockConstants.classNames);
    const anchorId = block.getAnchorId();

    const title = block.getFieldValue(ArticleCategoryHeroBlockConstants.title);
    const titleLevel = block.getFieldValue(ArticleCategoryHeroBlockConstants.titleLevel);
    const body = block.getFieldValue(ArticleCategoryHeroBlockConstants.body);
    const backgroundAsset = block.getFieldValue(ArticleCategoryHeroBlockConstants.backgroundAsset);
    const mobileBackgroundAsset = block.getFieldValue(ArticleCategoryHeroBlockConstants.mobileBackgroundAsset);
    const ctas = block.getFieldValue(ArticleCategoryHeroBlockConstants.ctas);
    const disclaimer = block.getFieldValue(ArticleCategoryHeroBlockConstants.disclaimer);

    const mainAsset = block.getFieldValue(ArticleCategoryHeroBlockConstants.mainAsset);
    const mobileAsset = block.getFieldValue(ArticleCategoryHeroBlockConstants.mobileAsset);
    const textToTheRight = block.getFieldValue(ArticleCategoryHeroBlockConstants.textToTheRight);
    const backgroundColor = block.getFieldValue(ArticleCategoryHeroBlockConstants.backgroundColor);
    const ariaLabel = block.getFieldValue(ArticleCategoryHeroBlockConstants.callToActionAriaLabel);
    const mainCtaIsALink = block.getFieldValue(ContentConstants.callToActionIsALink);
    const mainCtaBackgroundColor = block.getFieldValue(ContentConstants.ctaBackgroundColor);
    const mainCtaLabel = block.getFieldValue(ContentConstants.callToActionLabel);
    const mainCtaLink = block.getFieldValue(ContentConstants.callToActionLink);
    const isWhiteText = false;
    const isMedium = props?.isMedium;

    return (
        <div className={`ob-article-category-hero ${className}`} id={anchorId} style={{backgroundColor: backgroundColor || null}}>
            <Backdrop>
                <Backdrop.Image image={backgroundAsset} mobileImage={mobileBackgroundAsset}/>
                <Backdrop.Content className='ob-article-category-hero-content'>
                    <div className='ob-article-category-hero-wrapper'>
                        {textToTheRight && !isMedium && (
                            <Image image={mainAsset} />
                        )}

                        {isMedium && (
                            <Image image={mobileAsset} />
                        )}

                        <div className='ob-article-category-hero-inner'>
                            <Heading className='ob-article-category-hero-heading' tag={`h${titleLevel}`}>
                                {title}
                            </Heading>

                            <p className='ob-article-category-hero-text'>
                                {body}
                            </p>

                            <ul className='ob-article-category-hero-cta-list'>
                                {ctas && ctas.map((cta, ctaIndex) => (
                                    <li className='ob-article-category-hero-cta-item' key={ctaIndex}>
                                        <a className='ob-article-category-hero-cta-link ob-link primaryGrey'
                                            aria-label={cta?.fields?.linkAriaLabel}
                                            title={cta?.fields?.linkAriaLabel}                                                        href={cta.fields.url}>
                                            <Icon className='ob-article-category-hero-cta-icon'
                                                name={ArticleCategoryHeroBlockConstants.chevronRight}
                                                roundedIcon={ArticleCategoryHeroBlockConstants.blue} size={1.4}/>

                                            {cta.fields.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            {mainCtaLink &&
                                <div className='ob-article-category-hero-obLink-container'>
                                    {mainCtaIsALink ? (
                                        <ObLink href={`${mainCtaLink}`}
                                                className={`event_external_link ${mainCtaBackgroundColor === ContentConstants.White ? ContentConstants.white : ContentConstants.primaryGrey} ${isWhiteText ? ContentConstants.white : ContentConstants.primaryGrey}`}
                                                dataActionDetail={mainCtaLink}
                                                ariaLabel={title ? `${title}-${mainCtaLabel}-${ContentConstants.ctaName}` : mainCtaLabel}
                                                title={title ? `${title}-${mainCtaLabel}-${ContentConstants.ctaName}` : mainCtaLabel}
                                                icon={ContentConstants.chevronRight}>
                                            <Icon name={ContentConstants.chevronRight} roundedIcon={isWhiteText ? ContentConstants.white : ContentConstants.blue} />
                                            <span dangerouslySetInnerHTML={{__html: sanitize(mainCtaLabel)}}></span>
                                        </ObLink>
                                    ) : (
                                        <Button
                                            whiteTheme={isWhiteText || mainCtaBackgroundColor === ContentConstants.White}
                                            borderTheme={!isWhiteText && mainCtaBackgroundColor === ContentConstants.transparentWithBorder}
                                            invertedTheme={isWhiteText && mainCtaBackgroundColor === ContentConstants.transparentWithBorder}
                                            tag='a'
                                            ariaLabel={ariaLabel ? `${ariaLabel}` : mainCtaLabel}
                                            title={ariaLabel ? `${ariaLabel}`: mainCtaLabel}
                                            className={'event_external_link'}
                                            dataActionDetail={mainCtaLink}
                                            href={mainCtaLink}>
                                            {mainCtaLabel}
                                        </Button>
                                    )}
                                </div>
                            }
                            {disclaimer &&
                                <Disclaimer document={disclaimer} />
                            }
                        </div>
                        {!textToTheRight && !isMedium && (
                            <Image image={mainAsset} />
                        )}
                    </div>
                </Backdrop.Content>
            </Backdrop>
        </div>
    );
}

export default mediaQueryHOC(ArticleCategoryHeroBlock);
// This export is for unit testing (do not remove) :
export const ArticleCategoryHeroBlockTest = ArticleCategoryHeroBlock;
