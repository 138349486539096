import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  SearchBarConstants,
  SearchBlockConstants,algoliaReducerConstants
} from '../../adapters/helpers/Constants';
import { connectSearchBox } from 'react-instantsearch-dom';
import {CustomAutocomplete} from '../ModernWebAlgolia/AutocompleteHits'
import Icon from '../Icon/Icon';
import { removeHtmlTag, handleGAEvents } from '../../adapters/helpers/Utils'

const SearchBox = ({ refine,state,dispatch }) => {


  useEffect(() => {
    let queryParm = state.queryParm
    if(state.queryParm != '' && (state.searchValue == '' || state.searchValue == undefined || state.searchValue == null) && state.hitsResponse.length == 0){
      dispatch({ type: algoliaReducerConstants.searchValue,payload:removeHtmlTag(queryParm),...state });
      dispatch({ type: algoliaReducerConstants.SearchClicked,payload:true,...state });
      refine(queryParm)
    }
  }, [])


  const searchSubmit = (event) => {
    event.preventDefault();
  
    if(state?.searchValue.length > 3 && state?.queryParm !== state.searchValue && state.searchValue!==''){
      handleGAEvents(SearchBarConstants.eventCategory, SearchBarConstants.eventAction, state.searchValue)
      refine(state.searchValue);
      window.location.hash =
      SearchBlockConstants.queryHashName +
      SearchBlockConstants.queryHashEqual +
      state?.searchValue; 
      dispatch({ type: algoliaReducerConstants.SearchClicked,payload:true,...state });
      dispatch({ type: algoliaReducerConstants.showAutocomplete,payload:false,...state });
      dispatch({ type: algoliaReducerConstants.queryParm,payload:state?.searchValue,...state });
      dispatch({ type: algoliaReducerConstants.isSpinning,payload:true,...state });
    }
  };

  const validateScriptInjections = (userInput) => {
    const scriptInjectionPattern = /<script.*?>|javascript:/i;
    if (scriptInjectionPattern.test(userInput)) return true;
    return false;
  }

  const searchOnChangeFun = (event) => {
    if (validateScriptInjections(event.target.value)) {
      console.error('Please enter the valid text input.')
      dispatch({ type: algoliaReducerConstants.searchValue,payload:'',...state });
      return false;
    }
    dispatch({ type: algoliaReducerConstants.searchValue,payload:event.target.value,...state });
    if(event.target.value.length > 3){
      dispatch({ type: algoliaReducerConstants.showAutocomplete,payload:true,...state });
    }else{
      dispatch({ type: algoliaReducerConstants.showAutocomplete,payload:false,...state });
    }
    dispatch( { type: algoliaReducerConstants.SearchClicked, payload: false, ...state } );
    document.getElementById('searchButton').disabled = document.getElementById("searchText") && document.getElementById("searchText").value === "" ? true : false;
    refine(event.target.value);    
  }


  return (
    <div className='ob-search-block__search'>
      <form
        noValidate
        action=''
        className='ob-search-bar-form'
        role='search'
        onSubmit={searchSubmit}
      >
        <h1 id='searchTitle' className='ob-search-block__search-title'>
          <label htmlFor='searchText'>{state?.searchStaticContent?.mainTitle ? state.searchStaticContent.mainTitle.toUpperCase() : ''}</label>
        </h1>
        <div className='ob-search-bar'>
          <input
            id='searchText'
            type='text'
            autoComplete='off'
            value={state.searchValue}
            onChange={searchOnChangeFun}
          />
          <button
            type={SearchBarConstants.submit}
            id="searchButton"
            className='ob-search-bar-icon'
            onClick={searchSubmit}
            disabled
          >
        
              {state?.isSpinning ?
                        <div className="ob-search-bar-spinner"><div/></div> :
                        <Icon name={SearchBarConstants.search} arialable={'Search'}/>}
                  
        
          </button>
          <div role="status" aria-live="polite">
            <CustomAutocomplete state={state} dispatch={dispatch} />
          </div>
        </div>
      </form>
    </div>
    
  );
};

SearchBox.propTypes = {
  currentRefinement: PropTypes.any,
  refine: PropTypes.any,
  searchClicked : PropTypes.bool,
  state: PropTypes.object,
  dispatch : PropTypes.any
};

export const CustomSearch = connectSearchBox(SearchBox);
