import { sanitize } from 'isomorphic-dompurify';
import PropTypes from 'prop-types';
import React from 'react';

export default Label;

export function Label({ label, tokens }) {
    if (!label) {
        return '';
    } else if (tokens) {
        return replaceTokensFromString(label?.fields?.text || label, tokens);
    } else {
        return label?.fields?.text || label;
    }
}

Label.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    tokens: PropTypes.object,
};

export function LabelHTML({ label, tokens, ...props }) {
    if (!label) {
        return (
            <span {...props} />
        );
    } else if (tokens) {
        return (
            <span dangerouslySetInnerHTML={{ __html: sanitize(replaceTokensFromString(label?.fields?.text || label, tokens)) }} {...props} />
        );
    } else {
        return (
            <span dangerouslySetInnerHTML={{ __html: sanitize(label?.fields?.text || label) }} {...props} />
        );
    }
}

LabelHTML.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    tokens: PropTypes.object,
};

export function replaceTokensFromString(s, tokens) {
    for (let tokenKey in tokens) {
        s = s.replace(new RegExp('{' + tokenKey + '}', 'g'), tokens[tokenKey]);
    }

    return s;
}
