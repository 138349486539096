import Image from '../Image/Image';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading/Heading';
import Backdrop from '../Backdrop/Backdrop';
import {splitIntoTwoArrays} from '../../adapters/helpers/Utils';
import Disclaimer from '../Disclaimer/Disclaimer';
import { sanitize } from 'isomorphic-dompurify';

function DesignedByDentistBlock({document}) {
    const title = document?.fields?.title;
    const anchorId = document?.fields?.anchorId;
    const classNames = `ob-designed-by-dentist ${document?.fields?.classNames}`;
    const backgroundAsset = document?.fields?.backgroundAsset;
    const mobileBackgroundAsset = document?.fields?.mobileBackgroundAsset;
    const desktopMainAsset = document?.fields?.desktopMainAsset;
    const mobileMainAsset = document?.fields?.mobileMainAsset;
    const disclaimer = document?.fields?.disclaimer;

    const features = document?.fields?.features;
    const featuresMobile = document?.fields?.featuresMobile || [];
    const [featureListSplit, setFeatureListSplit] = useState([]);

    useEffect(() => {
        setFeatureListSplit(splitIntoTwoArrays(features));
    }, []);

    const renderFeatureListItems = (feature, index) => (
        <li className='ob-designed-by-dentist__feature-list-item' key={index}>
            <p className='ob-designed-by-dentist__feature-title' dangerouslySetInnerHTML={{__html: sanitize(feature?.fields?.title)}}></p>
            <p className='ob-designed-by-dentist__feature-description'>{feature?.fields?.description}</p>
        </li>
    );

    return (
        <div id={anchorId} className={classNames}>
            <Backdrop>
                <Backdrop.Image sm={mobileBackgroundAsset} md={backgroundAsset} />
                <Backdrop.Content>
                    <div className='ob-designed-by-dentist__container'>
                        <Heading className='ob-designed-by-dentist__title'>
                            {title}
                        </Heading>
                        <div className={`ob-designed-by-dentist__list-container ${featuresMobile.length > 0 ? 'ob-designed-by-dentist__list-container--desktop' : ''}`}>
                            {featureListSplit?.length > 0 &&
                                <ul className={'ob-designed-by-dentist__feature-list ob-designed-by-dentist__feature-list--first-half'}>
                                    {featureListSplit[0].map((feature, index) => renderFeatureListItems(feature, index))}
                                </ul>
                            }

                            {desktopMainAsset &&
                                <div className={'ob-designed-by-dentist__mainAssetContainer ob-designed-by-dentist__mainAssetContainer-desktop'}>
                                    <Image image={desktopMainAsset}/>
                                </div>
                            }
                            {featureListSplit?.length > 1 &&
                                <ul className={'ob-designed-by-dentist__feature-list ob-designed-by-dentist__feature-list--second-half'}>
                                    {featureListSplit[1].map((feature, index) => renderFeatureListItems(feature, index))}
                                </ul>
                            }
                        </div>
                        {featuresMobile.length > 0 &&
                            <div className='ob-designed-by-dentist__list-container ob-designed-by-dentist__list-container--mobile'>
                                {mobileMainAsset &&
                                <div className={'ob-designed-by-dentist__mainAssetContainer ob-designed-by-dentist__mainAssetContainer-mobile'}>
                                    <Image image={mobileMainAsset}/>
                                </div>
                                }
                                <ul className='ob-designed-by-dentist__feature-list'>
                                    {featuresMobile.map((feature, index) => renderFeatureListItems(feature, index))}
                                </ul>
                            </div>
                        }
                        {disclaimer && (
                            <div className='ob-designed-by-dentist__disclaimer'>
                                <Disclaimer document={disclaimer} />
                            </div>
                        )}
                    </div>
                </Backdrop.Content>
            </Backdrop>
        </div>
    )
}

export default DesignedByDentistBlock;

DesignedByDentistBlock.propTypes = {
    document: PropTypes.object,
};
