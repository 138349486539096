/* eslint-disable react/prop-types */
import React from 'react';
import { SearchBarConstants,SearchBlockConstants,algoliaReducerConstants } from '../../../adapters/helpers/Constants';

import { connectAutoComplete } from 'react-instantsearch-dom';
import { sanitize } from 'isomorphic-dompurify';

const Autocomplete = ({ hits, refine,state,dispatch }) => {
  let showAutocomplete = state.showAutocomplete
  const clickAutocompleteSugg = (e,hit) => {
    e.preventDefault();
    window.location.hash = SearchBlockConstants.queryHashName + SearchBlockConstants.queryHashEqual + hit.title;
    refine(hit.title)
    dispatch({ type: algoliaReducerConstants.queryParm,payload:hit.title,...state });
    dispatch({ type: algoliaReducerConstants.SearchClicked,payload:true,...state });
    dispatch({ type: algoliaReducerConstants.showAutocomplete,payload:false,...state });
    dispatch({ type: algoliaReducerConstants.searchValue,payload:hit.title,...state });
  }
  const onBlurSearchBox = () =>{
    if(state.showAutocomplete){
      dispatch({ type: algoliaReducerConstants.showAutocomplete,payload:false,...state });
    }
  }
  const afterClick = () => {
    dispatch({ type: algoliaReducerConstants.isSpinning,payload:true,...state });
  }
  return (
    showAutocomplete && hits.length > 0 && (<div id="autocomplete-sugg" 
    onBlur={onBlurSearchBox} className="ob-search-bar-autocomplete">
      <h2 className="ob-search-bar-autocomplete-title">
        {SearchBarConstants.common_search}
      </h2>
      <div className="flex flex-col align-center justify-center p-2 shadow-lg">
        <ul className="ob-search-bar-autocomplete-results">
          {
            hits.map((hit) => (
              <li
                className="ob-search-bar-autocomplete__result-item"
                key={hit.objectID}
              >
                <button
                  className="ob-search-bar-autocomplete__result-item-button event_search"
                  data-index={hit.objectID}
                  role="link"
                  data-action-detail={hit?.title}
                  onClick={(e)=>{clickAutocompleteSugg(e,hit);afterClick()}}
                  dangerouslySetInnerHTML={{ __html: sanitize(hit._highlightResult.title.value) }}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>)
  );
};

export const CustomAutocomplete = connectAutoComplete(Autocomplete);
