import {SeriesComparisonItemConstants} from '../../adapters/helpers/Constants';
import C from 'classnames';
import {getColorStyle, stripHtml} from '../../adapters/helpers/Utils';
import Button from '../Button/Button';
import Image from '../Image/Image';
import MarkdownText from '../MarkdownText/MarkdownText';
import { getReviewAndRatingId } from '../../adapters/helpers/Utils';
import { addBVCollectionPageCallback } from '../../adapters/helpers/Utils';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {handleColorNameFormat} from '../../adapters/helpers/Utils'
import { sanitize } from 'isomorphic-dompurify';
export default function SeriesComparisonItem(props) {
    const {document: doc, product, onClickCallback} = props;

    const handleColorLabel = doc.fields.handleColorLabel;

    const [selectedVariantIndex, setSelectedVariant] = useState(0);

    const productOptions = product?.fields?.options;
    const productVariants = product?.fields?.productVariants;
    const productVariant = productVariants && productVariants[selectedVariantIndex];

    const productImage = productVariant && productVariant?.fields?.mainAsset || product?.fields?.mainAsset;

    const buyNowLabel = product?.fields?.buyNowLabel;
    const buyNowDataActionDetail = product?.fields?.buyNowDataActionDetail;

    const title = product?.fields?.shortTitle || product?.fields?.title;
    const comingSoonText = product?.fields?.comingSoonLabel?.fields?.text || '';
    const comingSoonLink = product?.fields?.comingSoonLink || '';
    const buyNowDeactivated = product?.fields?.deactivateBuyNowButton || false;
    const buyNowLabelText = buyNowLabel?.fields?.text;

    const reviewAndRatingId = getReviewAndRatingId(product);

    useEffect(() => {
        addBVCollectionPageCallback();
    });

    return (
        <div className="ob-series-comparison__product">
            <div className="ob-series-comparison__product-pic">
                {!!productImage && (
                    <div className="ob-series-comparison__product-pic-wrap">
                        <Image className="ob-series-comparison__product-pic-image"
                            image={productImage}
                        />
                    </div>
                )}
            </div>

            <div className="ob-series-comparison__product-info">
                <h3 className="ob-series-comparison__product-name">
                    <a aria-label={stripHtml(title)}  className="ob-series-comparison__product-name-link ob-link event_button_click" data-action-detail={stripHtml(title)} href={product?.fields?.slug?.fields?.slug} dangerouslySetInnerHTML={{__html: sanitize(title)}}>
                    </a>
                </h3>


                {(productVariants && productVariants.length > 0) && (
                    <div className="ob-series-comparison__product-colors">
                        <div itemScope itemType={SeriesComparisonItemConstants.itemType}>
                            <meta itemProp={product?.fields?.title} content={SeriesComparisonItemConstants.ProductName}/>
                            <div data-bv-show={SeriesComparisonItemConstants.ratingSummary}
                                data-bv-product-id={reviewAndRatingId}></div>
                        </div>

                        {buyNowLabel && !buyNowDeactivated &&
                            <Button
                                onClick={event => onClickCallback(event)}
                                sku={productVariants[selectedVariantIndex]?.fields?.sku}
                                ariaLabel={buyNowLabelText ?stripHtml(`${buyNowLabelText} for ${title}`) : stripHtml(title)}
                                title={stripHtml(title)}
                                dataActionDetail={stripHtml(product?.fields?.title)} size='medium'>
                                {buyNowLabel.fields.text}
                            </Button>
                        }

                        {buyNowDeactivated &&
                            <Button
                                tag={'a'}
                                disabled={comingSoonLink === ''}
                                href={comingSoonLink}
                                size={'medium'}
                                target={'_blank'}
                                ariaLabel={buyNowLabelText ?stripHtml(`${buyNowLabelText} for ${title}`) : stripHtml(title)}
                                title={stripHtml(title)}
                            >
                                {comingSoonText}
                            </Button>
                        }

                        {(productVariants && productVariants.some(variant => variant?.fields?.color !== undefined)) &&
                            <h4 className="ob-series-comparison__product-subheading">
                                {handleColorLabel.fields.text}
                            </h4>
                        }

                        <ul className="ob-series-comparison__product-colors-list">
                            {productVariants && productVariants.map((variant, i) => {
                                const color = variant?.fields?.color;
                                if (color) {
                                    return (
                                        <li className="ob-series-comparison__product-colors-item" key={i}>
                                            <button aria-label={color.fields.name}
                                                type={SeriesComparisonItemConstants.button}
                                                className={C('ob-series__color ob-series__color--large-mobile event_button_click', {
                                                    ['ob-series__color--active']: i === selectedVariantIndex
                                                })}
                                                style={getColorStyle(color)}
                                                data-action-detail={`${color.fields.name}_${stripHtml(title)}`}
                                                onClick={() => setSelectedVariant(i)}
                                                aria-pressed={i === selectedVariantIndex ? true : false}
                                            >
                                                {color.fields.name}
                                            </button>
                                            {color.fields &&( <div className='color-container'>
                                                <label className={i === selectedVariantIndex?'ob-color-name-highlighted':'ob-color-name'}>{handleColorNameFormat(color.fields.name)}</label>
                                                </div>)}
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>
                )}

                {!!productOptions && (
                    <MarkdownText className="ob-series-comparison__product-options-text"
                        value={productOptions}
                    />
                )}
            </div>
        </div>
    );
}

SeriesComparisonItem.propTypes = {
    document: PropTypes.any,
    product: PropTypes.any,
    onClickCallback: PropTypes.func,
};
