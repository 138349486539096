import LayoutContext from '../Layout/LayoutContext';
import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import Block from '../../adapters/helpers/Block';
import Eyebrow from '../Eyebrow/Eyebrow';
import Button from '../Button/Button';
import ObLink from '../ObLink/ObLink';
import Image from '../Image/Image';
import {SpotlightStickyBarConstants} from '../../adapters/helpers/Constants';
import { sanitize } from 'isomorphic-dompurify';

export default function SpotlightStickyBar(props) {
    const block = new Block(props);
    const scrollToPage =  block.getFieldValue(SpotlightStickyBarConstants.scrollTo);
    const callToActionLink =  block.getFieldValue(SpotlightStickyBarConstants.callToActionLink);
    const callToActionLabel =  block.getFieldValue(SpotlightStickyBarConstants.callToActionLabel);
    const logoLink =  block.getFieldValue(SpotlightStickyBarConstants.logoLink);
    const logo =  block.getFieldValue(SpotlightStickyBarConstants.logo);
    const CCPALabel = props?.document?.fields?.CCPALabel;
    const adChoicesLink = props?.document?.fields?.adChoicesLink;
    const adChoicesLinkText = props?.document?.fields?.adChoicesLinkText;

    let className = SpotlightStickyBarConstants.stickyBarClassName;
    
    const layoutContext = useContext(LayoutContext);
    const { isContestVisible } = (layoutContext ? layoutContext : {isContestVisible: false});
    
    const hideZonesIfContestVisible = () => {
        if (isContestVisible) {
            className += ' hideStickyBar';
        }
    };
    
    hideZonesIfContestVisible();
    
    useEffect(() => {
        className = SpotlightStickyBarConstants.stickyBarClassName;
        hideZonesIfContestVisible();
        
    }, [isContestVisible]);
    
    const onClick = (event) => {
        if (process.env.MARKET == 'es-es') {
            try {
                /* eslint-disable */
                if(versaTagObj) {
                    versaTagObj.generateRequest('https://io.oralb.es/es-es/Inscribe-form.html');
                }
                /* eslint-enable */
            }catch(e) {
                console.info('versaTag is not defined')
            }
        }
        props.onClickCallback(event);
    };

    return (
        <div className={className}>
            <div className='ob-SpotlightStickyBar__wrapper'>
                <ObLink href={logoLink} className='ob-SpotlightStickyBar-link event_button_click' 
                        dataActionDetail={logo?.fields?.alternateText}>
                    <Image image={logo}></Image>
                </ObLink>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                <div className="ob-SpotlightStickyBar__eyebrow-container" tabIndex={0}>
                    <Eyebrow tag={'h1'} whiteText={true}>{block.getFieldValue(SpotlightStickyBarConstants.title)}</Eyebrow>
                </div>
                
                <div className='ob-SpotlightStickyBar__button-zone'>
                    {/* CCPA Lalel is the privacy setting button in the header */}
                    { CCPALabel &&
                        <span dangerouslySetInnerHTML={{__html: sanitize(`
                                <a aria-label='${CCPALabel}' class='ob-SpotlightStickyBar__CCPA' href="javascript:void(0)" onclick="Optanon.ToggleInfoDisplay();">${CCPALabel}</a>
                            `)}}>
                        </span>
                    }

                    { adChoicesLink && adChoicesLinkText &&
                        <ObLink tag={'a'} href={adChoicesLink?.fields?.url} className='ob-SpotlightStickyBar__adchoice' isExternalLink={true} isRelNofollow={true}>
                            <>
                                <span>{adChoicesLinkText}</span>
                                <Image image={adChoicesLink?.fields?.image} noLazyLoad={true} />
                            </>
                        </ObLink>
                    }
                    
                    {(scrollToPage && callToActionLabel) &&
                        <Button invertedTheme size={SpotlightStickyBarConstants.medium}
                            dataCustom={scrollToPage}
                            className="event_button_click"
                            dataActionDetail={SpotlightStickyBarConstants.SignUp}
                            onClick={event => { onClick(event) }}>
                            {callToActionLabel}
                        </Button>
                    }
                    {(scrollToPage == null && callToActionLink && callToActionLabel) &&
                        <Button invertedTheme
                            size={SpotlightStickyBarConstants.medium}
                            tag='a'
                            href={callToActionLink}
                            className="event_button_click"
                            dataActionLabel={SpotlightStickyBarConstants.SignUp}>
                            {callToActionLabel}
                        </Button>
                    }

                </div>

            </div>
        </div>
    )
}
SpotlightStickyBar.propTypes = {
    onClickCallback: PropTypes.func
};
