import React, {useEffect, useState} from 'react';
import { inputs } from './inputs';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Eyebrow from '../../../../Eyebrow/Eyebrow';
import Image from '../../../../Image/Image';
import { SubmitButton, FormInput,  } from '../../../components';
import { customSuperJsonParse, formatDate, formatDateToStringValue } from '../../../../../adapters/helpers/Utils';
import { getIsCreatingAccountFromLocalStorage, getJanrainCaptureProfileDataFromLocalStorage} from '../../../helpers/localstorage.helpers';
import {redirectTo} from '../../../helpers/redirect.helpers';
import {
    getPostReplaceEntityObject,
    postReplaceEntity,
    refreshEntity
} from '../../../../../adapters/model/service/api/svc';
import {
    getCountryId,
    postGWEProductWarrantyEmailNotification,
    postWarrantyRegistration
} from '../../../../../adapters/model/service/warrantyExtension/warrantyExtension.helpers';
import { WarrantyExtensionConstants } from '../../../../../adapters/helpers/Constants';
import moment from 'moment';


const RegisterProductForm = ({ formHeading, labels, receiptPicture, successRedirectPath, activateGWEWarrantyEmail, dateOfPurchaseValidation, validPurchaseTimeWindow }) => {
    const [productData, setProductValue] = useState({});
    const [formValidated, setFormValidated] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [primaryProductDetail, setPrimaryProductDetail] = useState({});
    const [secondaryProductDetail, setSecondaryProductDetail] = useState({});
    const [dateErrorText, setDateErrorText] = useState('');
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);

    const setProductData = data => {
        setProductValue(data);
    };


    useEffect(()=>{
        setPrimaryProductDetail(customSuperJsonParse(localStorage.getItem(WarrantyExtensionConstants.gasPrimaryProductDetailKey)) || {})
        setSecondaryProductDetail(customSuperJsonParse(localStorage.getItem(WarrantyExtensionConstants.gasSecondaryProductDetailKey)) || {})
        setIsCreatingAccount(getIsCreatingAccountFromLocalStorage() == 'true')
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const janrainProfile = customSuperJsonParse(getJanrainCaptureProfileDataFromLocalStorage());
        
        const countryId = await getCountryId(janrainProfile?.primaryAddress?.country)
        if(countryId !== -1) {
            janrainProfile.countryId = countryId;
        }

        let postObject = getPostReplaceEntityObject(janrainProfile);


        // pass postObject with other attributes updated or not
        saveProduct(janrainProfile, postObject);
    };

    const saveProduct = (janrainProfile, janrainPostObject) => {
        const newJanrainProduct = {
            purchaseDate: formatDateToStringValue(productData.primary.purchaseDate, 'DD/MM/YYYY', 'YYYY-MM-DD'),
            registrationDate: formatDateToStringValue(productData.primary.registrationDate, 'DD/MM/YYYY', 'YYYY-MM-DD'),
            imageUrl: null,
            traitLastUpdated: new Date(),
            traitLastUpdatedClientId: process.env.JANRAIN_CAPTURE_CIENT_ID,
            traitDate: null,
            traitValue: null,
            productTypeCode : productData.primary.typeCode
        }
        let newJanrinComboProduct = null;
        if(productData.secondary) {
            newJanrinComboProduct = {
                purchaseDate: formatDateToStringValue(productData.secondary.purchaseDate, 'DD/MM/YYYY', 'YYYY-MM-DD'),
                registrationDate: formatDateToStringValue(productData.secondary.registrationDate, 'DD/MM/YYYY', 'YYYY-MM-DD'),
                imageUrl: null,
                traitLastUpdated: new Date(),
                traitLastUpdatedClientId: process.env.JANRAIN_CAPTURE_CIENT_ID,
                traitDate: null,
                traitValue: null,
                productTypeCode : productData.secondary.typeCode
            }
        }
        

        // add new product
        let localStorageProductList = janrainProfile?.traitProductType;

        // remove id from localStorage product. /entity.update doesn't accept them.
        localStorageProductList?.forEach(localStorageProduct => {
            delete localStorageProduct.id;
        });

        janrainPostObject['traitProductType'] = localStorageProductList;

        if (janrainProfile?.traitProductType?.length == 0) {
            janrainPostObject['primaryProduct'] = productData.primary.typeCode + '_' + productData.primary.purchaseDate;
            const traitPrimaryBrushValue = {
                traitValue: null,
                productTypeCode : productData.primary.typeCode
            }
            janrainPostObject['traitPrimaryBrush'] = traitPrimaryBrushValue;
        }

        // postWarrantyRegistration success callback
        const onWarrantyRegistrationSuccess = (newJanrainProduct, registerData) => {
            // method postEmailNotification needs product with title attribute
            productData.primary.title = productData?.productName;

            function postReplaceEntityCallback(orderId) {
                function onRefreshEntitySuccess() {
                    function redirectToSuccessPath() {
                        redirectTo(successRedirectPath + `?product_id=${orderId}`);
                    }

                    if(activateGWEWarrantyEmail) {
                        postGWEProductWarrantyEmailNotification(janrainProfile, orderId, redirectToSuccessPath);
                    } else {
                        redirectToSuccessPath();
                    }
                }

                refreshEntity(onRefreshEntitySuccess);
            }

            postReplaceEntity(janrainPostObject, newJanrainProduct, postReplaceEntityCallback, postReplaceEntityCallback, registerData, newJanrinComboProduct);
        }

        // postWarrantyRegistration failure callback
        const onWarrantyRegistrationFail = () => {
            setIsSubmitting(false);
        }

        postWarrantyRegistration(productData, janrainProfile, newJanrainProduct, onWarrantyRegistrationSuccess, onWarrantyRegistrationFail);

    };


    const validateDate = (inputValue) => {
        const regex = new RegExp(dateOfPurchaseValidation.fields.pattern);
        let inputDate = {};
        const currentDate = new Date();
        const momentDatePurchaseWindow = moment(new Date()).subtract(validPurchaseTimeWindow, 'years') ;
        const momentDatePurchaseWindowformattedDate = momentDatePurchaseWindow.format('YYYY-MM-DD');
        const validPurchaseWindow = new Date(momentDatePurchaseWindowformattedDate)
        switch (inputValue.indexOf('/')) {
            case 2: {
                let year = inputValue.substr(inputValue.length - 4);
                let month = inputValue.substring(3, 5);
                let day = inputValue.substring(0, 2);
                inputDate = new Date(`${year}-${month}-${day}T00:00:00`);
                break;
            }
            case 4: {
                inputDate = new Date(inputValue);
                break;
            }
            default: {
                inputDate = currentDate;
            }
        }

        let isValid = regex.test(inputValue) && inputDate < currentDate && inputDate >= validPurchaseWindow;
        
        if (isValid) {
            setDateErrorText('');
            const today = new Date();
            let product = {
                primary: {
                    purchaseDate: formatDate(inputValue, '/', 'dd-mm-yyyy'),
                    typeCode: primaryProductDetail.typeCode,
                    productName: primaryProductDetail.name,
                    longDescription: primaryProductDetail.name,
                    mainAsset: primaryProductDetail.image,
                    registrationDate: formatDate(today, '/', 'dd-mm-yyyy'),
                    timeStamp: today.toUTCString()
                }
            };

            if(secondaryProductDetail.name){
                product = {...product, 
                    secondary: {
                        purchaseDate: formatDate(inputValue, '/', 'dd-mm-yyyy'),
                        typeCode: secondaryProductDetail.typeCode,
                        productName: secondaryProductDetail.name,
                        longDescription: secondaryProductDetail.name,
                        mainAsset: secondaryProductDetail.image,
                        registrationDate: formatDate(today, '/', 'dd-mm-yyyy'),
                        timeStamp: today.toUTCString() 
                    }
                }
            }
            setProductData(product);
            setFormValidated(true);
        } else {
            setDateErrorText(dateOfPurchaseValidation.fields.errorMessage);
            setFormValidated(false);
        }

    };

    const handleDateOfPurchase = event => {
        let value = event.currentTarget.value;
        if (event.currentTarget.type !== 'date') {
            // This autopopulates slashes in case input[type="date"] isn't supported
            // eslint-disable-next-line no-useless-escape
            event.currentTarget.value = value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'');
        } else {
            value = value.replace(/-/g, '/')
        }
        if (primaryProductDetail.typeCode === null || primaryProductDetail.typeCode === '') {
            setDateErrorText(labels?.noProductSelectedLabel);
        } else {
            validateDate(value);
        }
    };
    

    return (
        <div className='ob-register-product'>
            <div className='ob-form__layout ob-register-product__wrapper'>
                <form className='ob-form__form' name={inputs.formName} onSubmit={handleSubmit}>
                    <h1 className='ob-form__title'>{formHeading}</h1>
                    <Eyebrow className='ob-register-product__step-title ob-register-product__heading'>{isCreatingAccount ? labels?.step1Heading : labels?.comesBackDescription}</Eyebrow>
                    <Eyebrow className='ob-register-product__product-list-title'>{labels?.brushListTitle}</Eyebrow>
                    <ul className='ob-register-product__product-group-names'>
                        {primaryProductDetail.name && <li>{primaryProductDetail.name}</li>}
                        {secondaryProductDetail.name && <li>{secondaryProductDetail.name}</li>}
                    </ul>
                    <Eyebrow className='ob-register-product__dop-disclaimer ob-register-product__dop-disclaimer-mobile'>{labels?.dateOfPurchaseDisclaimer}</Eyebrow>
                    <div className='ob-register-product__entry-list-wrapper'>
                        <div className='ob-register-product__entry-wrapper'>
                            <div className='ob-register-product__entry-section'>
                                <Eyebrow className='ob-register-product__dop-disclaimer'>{labels?.dateOfPurchaseDisclaimer}</Eyebrow>
                                <div className='ob-register-product__dop-select-wrapper'>
                                    <FormInput
                                        inputType={inputs.type.date}
                                        name={inputs.field.dateOfPurchase}
                                        placeholder={labels?.dateOfPurchasePlaceholder}
                                        validationErrorText={dateErrorText}
                                        onChange={handleDateOfPurchase}
                                        label={labels?.dateOfPurchaseLabel} />
                                </div>
                                <div className='ob-register-product__sample-receipt-display'>
                                    <Image image={receiptPicture} />
                                </div>
                            </div>
                        </div>
                        <div className='ob-register-product__image-wrapper'>
                            <div className={'ob-register-product__product-images'}>
                                {primaryProductDetail.image && <img src={primaryProductDetail.image} alt={'product image'} className={'ob-register-product__product-image'} />}
                                {secondaryProductDetail.image && <img src={secondaryProductDetail.image} alt={'combo product image'} className={'ob-register-product__product-image'} />}
                            </div>
                            <Eyebrow className='ob-register-product__product-image-disclaimer-title'>{labels?.productImageDisclaimerText}</Eyebrow>
                        </div>
                    </div>
                    {
                        <div className='ob-register-product__submit-button'>
                            <SubmitButton
                                isSubmitting={isSubmitting}
                                disabled={!formValidated || isSubmitting}
                                buttonText={labels?.registerProductButtonText}
                            />
                        </div>
                    }
                </form>
            </div>
        </div>
    )
};

RegisterProductForm.propTypes = {
    formHeading: PropTypes.string.isRequired,
    labels: PropTypes.shape({
        step1Heading: PropTypes.string.isRequired,
        step2Heading: PropTypes.string.isRequired,
        productGroupDescription: PropTypes.string,
        selectProductTypeLabel: PropTypes.string.isRequired,
        selectProductLabel: PropTypes.string.isRequired,
        dateOfPurchaseLabel: PropTypes.string.isRequired,
        dateOfPurchasePlaceholder: PropTypes.string.isRequired,
        noProductSelectedLabel: PropTypes.string.isRequired,
        oralCareGoals: PropTypes.string.isRequired,
        selectGoals: PropTypes.string.isRequired,
        reminderToReplaceLabel: PropTypes.string.isRequired,
        registerProductButtonText: PropTypes.string.isRequired,
        registerProductProductSelect: PropTypes.string.isRequired
    }).isRequired,
    productTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })).isRequired,
};

export default observer(RegisterProductForm);
