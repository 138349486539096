import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import Modal from 'react-modal';
import Icon from '../Icon/Icon';
import { SpotlightContentVideoConstants } from '../../adapters/helpers/Constants';
import Button from '../Button/Button';
import clicRegisterService from '../../adapters/model/service/gcs/clicRegisterService'
import validate from './validate'
import { sanitize } from 'isomorphic-dompurify';

function ClicRegister(props) {
    const { handleClose, isModelOpen, languageCode, registerPopup, isMedium ,isXSmall} = props
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,.7)',
            zIndex: 200
        },
        content: {
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            fontSize: '1.4rem',
            lineHeight: '2.1rem',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '123.6rem',
            width: isXSmall?'': '100%' ,
            height: isMedium? '90%' : '' ,
            backgroundColor: 'transparent'
        }
    };

    const { formTitle, firstName, lastName, email, termsAndCondition, agreeText, signUpText, backButtonText } = (registerPopup && registerPopup.fields) || {}
    const { inputs, handleInputChange, handleSubmit, errors, isError,
        isResponseReceived, responseMessage, resetState, isLoading } = clicRegisterService({ email: '', firstName: '', lastName: '', agree: 0 }, validate, languageCode, registerPopup);

    useEffect(() => {
        if (isModelOpen) {
            resetState()
        }
    }, [isModelOpen])
    const btnContent = <Button
        whiteTheme={false}
        borderTheme={false}
        borderThemeBlue={false}
        invertedTheme={false}
        tag="button"
        className={'event_external_link'}
        dataActionDetail={'clic register'}
        href={''}
        type='submit'
    >
        {signUpText}
    </Button>;


    const backBtn = <Button
        whiteTheme={false}
        borderTheme={false}
        borderThemeBlue={false}
        invertedTheme={false}
        tag="button"
        className={'event_external_link'}
        dataActionDetail={'clic register'}
        href={''}
        onClick={handleClose}
    >
        {backButtonText}
    </Button>;

    return <Modal
        isOpen={isModelOpen}
        onRequestClose={handleClose}
        closeTimeoutMS={250}
        style={customModalStyles}>
        <div className="sign-overlay">
            <div className="backdrop">
                <button className="close" aria-label="Close" type="button" onClick={handleClose}>
                    <Icon name={SpotlightContentVideoConstants.close} size="2.3" color="#fff" />
                </button>
                {(isResponseReceived || isLoading) ?
                    <div className='responseWrapper'>
                        {
                            isLoading ? <div className='register-spinner' />
                                : <>
                                    <p className='response-message'>{responseMessage}</p>
                                    <div className='back-btn'>
                                        {backBtn}
                                    </div>
                                </>
                        }
                    </div>
                    : <form name="emailForm" className="emailForm" onSubmit={handleSubmit}>
                        <div className='registerFormWrapper'>
                            <h2 className='title'>{formTitle}</h2>
                            <fieldset className='fieldsForm'>
                                <label className={errors?.email ? 'field-wrapper-error' : 'field-wrapper'}>
                                    <input aria-label={email} autoComplete='off' className={errors?.email ? 'input-red' : 'input-normal'} refs="email" type="text" size="30" onChange={handleInputChange} placeholder={email} name="email" value={inputs.email} id='email' />
                                    <span id="error">{errors && errors.email}</span>
                                </label>
                                <label className={errors?.firstName ? 'field-wrapper-error' : 'field-wrapper'}>
                                    <input aria-label={firstName} autoComplete='off' className={errors?.firstName ? 'input-red' : 'input-normal'} type="text" size="30" onChange={handleInputChange} placeholder={firstName} name='firstName' value={inputs.firstName} id='firstName' />
                                    <span id="error">{errors && errors.firstName}</span>
                                </label>
                                <label className={errors?.lastName ? 'field-wrapper-error' : 'field-wrapper'}>
                                    <input aria-label={lastName} autoComplete='off' className={errors?.lastName ? 'input-red' : 'input-normal'} type="text" size="30" onChange={handleInputChange} placeholder={lastName} name='lastName' value={inputs.lastName} id='lastName' />
                                    <span id="error">{errors && errors.lastName}</span>
                                </label>
                            </fieldset>
                        </div>
                        <p className='terms-condition' dangerouslySetInnerHTML={{ __html: sanitize(termsAndCondition) }}></p>
                        <div className='agreeWrapper'>
                            <label className="agree-label">
                                <input aria-label="agree" type="checkbox" name="agree" id="myCheck" value={inputs.agree} onChange={handleInputChange} />
                                <div className="text">
                                    <div className="text" dangerouslySetInnerHTML={{ __html: sanitize(agreeText) }}></div>
                                </div>
                            </label>
                        </div>
                        <div className='submit-btn-wrapper'>
                            <fieldset>
                                {
                                    btnContent
                                }
                            </fieldset>
                        </div>
                    </form>}
            </div>
        </div>
    </Modal>

}

export default mediaQueryHOC(ClicRegister)

ClicRegister.propTypes = {
    handleClose: PropTypes.func,
    isModelOpen: PropTypes.bool,
    languageCode: PropTypes.string,
    registerPopup: PropTypes.any
}

ClicRegister.defaultProps = {
    isModelOpen: false
}