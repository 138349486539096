import 'url-search-params-polyfill'
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {getSearchQueryParameter} from '../Janrain/Janrain/janrainForms/Janrain.helpers';
import JanrainForm from '../Janrain/Janrain/HOC/JanrainForm';
import {getJanrainCaptureProfileDataFromLocalStorage} from '../Janrain/helpers/localstorage.helpers';
import { enhanceProducts } from '../Janrain/helpers/product.helpers';
import { recoverPreferencesFromUserData } from '../Janrain/helpers/setting.helpers';
import Label from '../Label/Label';
import Icon from '../Icon/Icon';
import SlideToggle from 'react-slide-toggle';
import {ProfileForm} from '../Janrain/Janrain/janrainForms';
import { customSuperJsonParse, isObjectEmpty, objectWithLabel} from '../../adapters/helpers/Utils';
import useJanrainGlobalState from '../Janrain/Janrain/janrainForms/useJanrainGlobalState';
import {getConsumerProduct} from '../../adapters/model/service/warrantyExtension/warrantyExtension.helpers';
import {refreshEntity} from '../../adapters/model/service/api/svc';

export default function Profile({document}) {
    const [isCallbackInURL, setCallbackInURL] = useState(false);
    const [utmSource, setUtmSource] = useState(undefined);
    const [userData, setUserData] = useState(undefined);
    const [oralbProductList, setOralbProductList] = useState(undefined);
    const [oralbBenefitPreferenceList, setOralbBenefitPreferenceList] = useState(undefined);
    const [firstName, setFirstName] = useState('');
    const [globalState] = useJanrainGlobalState();
    const [isGCSAPIDown, setGCSAPIDown] = useState(false);

    const [currentTab, setCurrentTab] = useState(0);
    const currentTabHashString = 'currentTab=';

    const title = document?.fields?.title;
    const profileProductManagementTab = document?.fields?.profileProductManagementTab?.fields;
    const profileEditTab = document?.fields?.profileEditTab?.fields;
    const profileBenefitPreferencesTab = document?.fields?.profileBenefitPreferencesTab?.fields;
    const productCategoryList = document?.fields?.registerProductBlock?.fields?.registerProductCategoryList;
    const warrantyExtensionTime = document?.fields?.registerProductBlock?.fields?.warrantyExtensionTime || 1;

    const janrainErrors = objectWithLabel(document?.fields?.janrainErrors?.fields);

    const commonLabels = {
        modalCancelButton: document?.fields?.modalCancelButton?.fields?.text,
        saveChangesLabel: document?.fields?.saveChangesLabel?.fields?.text,
        saveLabel: document?.fields?.saveLabel?.fields?.text,
        editModalSaveChangesTitle: document?.fields?.editModalSaveChangesTitle?.fields?.text,
        editModalSuccess: document?.fields?.editModalSuccess?.fields?.text,
        closeModal: document?.fields?.closeModal?.fields?.text,
    };

    const onSuccess = (gCSUserproductList) => {
        const userProducts = enhanceProducts(gCSUserproductList);
        userProducts.sort((a, b) => (a.orderProductId > b.orderProductId) ? 1 : -1)
        setOralbProductList(userProducts);
    }

    const onFailure = () => {
        setGCSAPIDown(true);
    }

    useEffect(() => {
        const urlParams = window.location.hash.substr(1);
        const tab = urlParams.replace(currentTabHashString, '');
        const searchParamsObject = new URLSearchParams(window.location.search);
        const utmSource = getSearchQueryParameter('utm_source');
        const isCallbackInURL = searchParamsObject.has('callback');
        const profileData = customSuperJsonParse(getJanrainCaptureProfileDataFromLocalStorage());
        // const userProducts = recoverProductsFromUserData(profileData, productCategoryList, warrantyExtensionTime);
        // get GCS warranty extension user product/order
        // let GCSproductList = getConsumerProduct(profileData?.consumerID);
        getConsumerProduct(onSuccess, onFailure, profileData, productCategoryList, warrantyExtensionTime);

        const oralbBenefitPreferenceListData = recoverPreferencesFromUserData(profileData);
        setCurrentTab(tab ? parseInt(tab, 10) : 0);
        setCallbackInURL(isCallbackInURL);
        setUtmSource(utmSource);
        setUserData(profileData);
        setFirstName(profileData?.givenName);
        setOralbBenefitPreferenceList(oralbBenefitPreferenceListData);

        // refresh janrain entity
        refreshEntity();
    }, []);

    useEffect(() => {
        if (globalState?.givenName) {
            setFirstName(globalState?.givenName)
        }
    }, [globalState]);

    return <JanrainForm needActiveSession={true}>
        {!isObjectEmpty(userData) &&
        <div className={'ob-profile'}>
            <h1 className={'ob-heading ob-profile__title'}>
                <Label label={title} tokens={{firstName: firstName}}/>
            </h1>
            <div className={'ob-profile__tab-list-container --desktop'}>
                <ul className={'ob-profile__tab-list'}>
                    <li className={`ob-profile__tab-item ${currentTab === 0 ? 'is-active' : ''}`}>
                        <a aria-label={profileProductManagementTab.title} href={`#${currentTabHashString}0`} className={'ob-profile__tab-btn'}
                           onClick={() => setCurrentTab(0)}>
                            {profileProductManagementTab.title}
                        </a>
                    </li>
                    <li className={`ob-profile__tab-item ${currentTab === 1 ? 'is-active' : ''}`}>
                        <a aria-label={profileEditTab.title} href={`#${currentTabHashString}1`} className={'ob-profile__tab-btn'}
                           onClick={() => setCurrentTab(1)}>
                            {profileEditTab.title}
                        </a>
                    </li>
                    <li className={`ob-profile__tab-item ${currentTab === 2 ? 'is-active' : ''}`}>
                        <a aria-label={profileBenefitPreferencesTab.title} href={`#${currentTabHashString}2`} className={'ob-profile__tab-btn'}
                           onClick={() => setCurrentTab(2)}>
                            {profileBenefitPreferencesTab.title}
                        </a>
                    </li>
                </ul>

            </div>
            <div className={'ob-profile__tab-list-container --mobile'}>
                <SlideToggle collapsed duration={500}>
                    {({onToggle, setCollapsibleElement, range, toggleState}) => {
                        const styles = range ? {opacity: Math.max(0, range)} : {opacity: 1};
                        return (
                            <div className={'ob-profile__tab-slide-toggle'}>
                                <button className={'ob-profile__tab-title'}
                                        onClick={onToggle}>
                                        <span>
                                            {currentTab === 0 && profileProductManagementTab.title}
                                            {currentTab === 1 && profileEditTab.title}
                                            {currentTab === 2 && profileBenefitPreferencesTab.title}
                                        </span>
                                    {toggleState === 'COLLAPSED' || toggleState === 'COLLAPSING' ? (
                                        <Icon name={'chevronDown'}/>
                                    ) : (
                                        <Icon name={'chevronTop'}/>
                                    )}
                                </button>

                                <ul ref={setCollapsibleElement} className={'ob-profile__tab-list'} style={styles}>
                                    {currentTab !== 0 &&
                                    <li className={'ob-profile__tab-item'}>
                                        <a aria-label={profileProductManagementTab.title} href={`#${currentTabHashString}0`} className={'ob-profile__tab-btn'}
                                           onClick={() => {
                                               setCurrentTab(0);
                                               onToggle();
                                           }}>
                                            {profileProductManagementTab.title}
                                        </a>
                                    </li>
                                    }
                                    {currentTab !== 1 &&
                                    <li className={'ob-profile__tab-item'}>
                                        <a aria-label={profileEditTab.title} href={`#${currentTabHashString}1`} className={'ob-profile__tab-btn'}
                                           onClick={() => {
                                               setCurrentTab(1);
                                               onToggle();
                                           }}>
                                            {profileEditTab.title}
                                        </a>
                                    </li>
                                    }
                                    {currentTab !== 2 &&
                                    <li className={'ob-profile__tab-item'}>
                                        <a aria-label={profileBenefitPreferencesTab.title} href={`#${currentTabHashString}2`} className={'ob-profile__tab-btn'}
                                           onClick={() => {
                                               setCurrentTab(2);
                                               onToggle();
                                           }}>
                                            {profileBenefitPreferencesTab.title}
                                        </a>
                                    </li>
                                    }
                                </ul>
                            </div>
                        )
                    }}
                </SlideToggle>
            </div>
            <ProfileForm
                userData={userData}
                oralbBenefitPreferenceList={oralbBenefitPreferenceList}
                oralbProductList={oralbProductList}
                currentTab={currentTab}
                profileProductManagementTab={profileProductManagementTab}
                commonLabels={commonLabels}
                janrainErrors={janrainErrors}
                profileEditTab={profileEditTab}
                profileBenefitPreferencesTab={profileBenefitPreferencesTab}
                isCallbackInURL={isCallbackInURL}
                utmSource={utmSource}
                setOralbProductList={setOralbProductList}
                isGCSAPIDown={isGCSAPIDown}
            />
        </div>
        }
    </JanrainForm>
}

Profile.propTypes = {
    document: PropTypes.object
};
