import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import DOMPurify from 'isomorphic-dompurify';
import PropTypes from 'prop-types';
import Image from '../Image/Image';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import Block from '../../adapters/helpers/Block';
import {
  Constants,
  miniContentBlockConstants,
} from '../../adapters/helpers/Constants';
const ResponsiveImage = dynamic(() =>
  import('../ResponsiveImage/ResponsiveImage')
);
import { toLower } from 'lodash';

const MiniContentBlock = (props) => {
  const { document: doc, isSmall, isXSmall } = props;

  const className = doc?.fields?.className;
  const anchorId = doc?.fields?.anchorId;
  const desktopBannerImage = doc?.fields?.desktopBannerImage;
  const mobileBannerImage = doc?.fields?.mobileBannerImage;
  const eyebrow = doc?.fields?.eyebrow;
  const headerText = doc?.fields?.headerText;
  const subText = doc?.fields?.subText;
  const secondSubText = doc?.fields?.secondSubText;
  const disclaimer = doc?.fields?.disclaimer;
  const bannerBackgroundColour = doc?.fields?.bannerBackgroundColour;
  const textColour = doc?.fields?.textColour;
  const headerTextStyle = doc?.fields?.headerTextStyle;
  const subTextStyle = doc?.fields?.subTextStyle;
  const eyebrowTextStyle = doc?.fields?.eyebrowTextStyle;
  const secondSubTextStyle = doc?.fields?.secondSubTextStyle;
  const bannerImagePosition = doc?.fields?.bannerImagePosition;
  const bannerVideo = doc?.fields?.bannerVideo;
  const videoThumbnail = doc?.fields?.videoThumbnail;
  const ctaButton = doc?.fields?.ctaButton;
  const ctaObject = ctaButton?.CTA;
  const appCTAObject = ctaButton?.AppCTA;
  const modalCTAObject = ctaButton?.modalCTA;
  const link = doc?.fields?.link;

  const textStyle = {
    color:
      textColour === miniContentBlockConstants.whiteTextColorValue
        ? 'white'
        : '',
    textAlign: isSmall ? 'center' : 'left',
    width: '100%',
  };

  const getUpdatedStyles = (styleType) => {
    const styleObject = isSmall ? styleType?.mobile : styleType?.desktop;

    return {
      ...textStyle,
      ...styleObject,
    };
  };

  const renderImage = () => {
    return (
      <div className='ob-mini-content-image-container'>
        {bannerVideo ? (
          bannerVideo ? (
            <div>
              {new Block(bannerVideo).renderBlockFromDocument(bannerVideo)}{' '}
            </div>
          ) : (
            videoThumbnail && (
              <div className='ob-carousel-tab-content-conatiner-image'>
                <ResponsiveImage document={videoThumbnail}></ResponsiveImage>
              </div>
            )
          )
        ) : (
          <Image
            className={
              isSmall
                ? 'ob-mini-content-mobile-image'
                : 'ob-mini-content-dektop-image'
            }
            image={isSmall ? mobileBannerImage : desktopBannerImage}
            altText={
              isSmall
                ? mobileBannerImage?.fields?.alternateText
                : desktopBannerImage?.fields?.alternateText
            }
          />
        )}
      </div>
    );
  };

  const renderText = () => {
    return (
      <div
        className='ob-mini-content-text-area-wrapper'
        style={{ backgroundColor: bannerBackgroundColour }}
      >
        {eyebrow && (
          <p
            className='ob-mini-content-eyebrow'
            style={getUpdatedStyles(eyebrowTextStyle)}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(eyebrow) }}
          />
        )}
        {headerText && (
          <h2
            className='ob-mini-content-header'
            style={getUpdatedStyles(headerTextStyle)}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(headerText) }}
          />
        )}
        {subText && (
          <>
            <p
              className='ob-mini-content-sub-text'
              style={getUpdatedStyles(subTextStyle)}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(subText),
                }}
              />
            </p>
          </>
        )}
        {secondSubText && (
          <p
            className='ob-mini-content-sub-text'
            style={getUpdatedStyles(secondSubTextStyle)}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(secondSubText),
            }}
          />
        )}
        {ctaButton && ctaObject && (
          <a
            target='_blank'
            rel='noopener noreferrer'
            className='ob-button ob-mini-content-cta'
            style={{
              backgroundColor:
                toLower(ctaObject.ctaColor) ===
                miniContentBlockConstants.whiteCtaColorValue
                  ? miniContentBlockConstants.whiteCtaColor
                  : miniContentBlockConstants.blueCtaColor,
              color:
                toLower(ctaObject.ctaColor) ===
                miniContentBlockConstants.whiteCtaColorValue
                  ? miniContentBlockConstants.blueCtaColor
                  : miniContentBlockConstants.whiteCtaColor,
            }}
            href={`${ctaObject.ctaLink}`}
          >
            <span>{ctaObject.ctaLabel}</span>
          </a>
        )}
        {ctaButton && appCTAObject && (
          <div className='ob-mini-content-app-cta-container'>
            <a
              className='ob-mini-content-app-cta'
              href={`${appCTAObject?.appStore?.ctaLink}`}
            >
              <img
                src={appCTAObject?.appStore?.ctaImage}
                alt={`${appCTAObject?.appStore?.ctaImageAlt}`}
              />
            </a>
            <a
              className='ob-mini-content-app-cta'
              href={`${appCTAObject?.playStore?.ctaLink}`}
            >
              <img
                src={appCTAObject?.playStore?.ctaImage}
                alt={`${appCTAObject?.playStore?.ctaImageAlt}`}
              />
            </a>
          </div>
        )}
        {disclaimer && (
          <p
            className='ob-mini-content-disclaimer'
            style={textStyle}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(disclaimer) }}
          />
        )}
      </div>
    );
  };

  const renderMiniContentBlock = () => {
    return (
      <>
        {bannerImagePosition && (
          <>
            {(bannerImagePosition === Constants.bannerImagePositionLeft ||
              bannerImagePosition === Constants.bannerImagePositionUp) &&
              renderImage()}
            {renderText()}
            {bannerImagePosition === Constants.bannerImagePositionRight &&
              renderImage()}
          </>
        )}
        {!bannerImagePosition && (
          <>
            {renderImage()}
            {renderText()}
          </>
        )}
      </>
    );
  };

  const renderBlock = () => {
    return (
      <div
        className={`ob-mini-content-block ${className ? className : ''}`}
        id={anchorId}
        style={{ backgroundColor: bannerBackgroundColour }}
      >
        {!isSmall && renderMiniContentBlock()}
        {isSmall && (
          <>
            {renderImage()}
            {renderText()}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {link && (
        <a className='ob-mini-content-anchor' target='_blank' href={`${link}`}>
          {renderBlock()}
        </a>
      )}
      {!link && renderBlock()}
    </>
  );
};

MiniContentBlock.propTypes = {
  document: PropTypes.object,
  isSmall: PropTypes.boolean,
};
export default mediaQueryHOC(MiniContentBlock);
