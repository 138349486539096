import React, { useEffect } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import Hit from './Hit';
import CustomStateResults from './Hit/CustomStateResults';
import CustomRefinementList from './Hit/RefinementList';
import Pagination from '../Pagination';
import { algoliaReducerConstants } from './../../../adapters/helpers/Constants'
import { removeHtmlTag } from './../../../adapters/helpers/Utils'
import { sanitize } from 'isomorphic-dompurify';

const Hits = ({ hits, state, dispatch }) => {
  const noHits = state.hitsResponse.length === 0;
  useEffect(() => {
    if (
      !state?.showAutocomplete &&
      state?.searchValue != '' &&
      state?.searchValue == state?.queryParm
    ) {
      dispatch({ type: algoliaReducerConstants.isSpinning, payload: false, ...state });
      dispatch({ type: algoliaReducerConstants.hitsResponse, payload: hits, ...state });
    }
  }, [hits]);
  let returnValue = null;
  if (noHits) {
    const noResultsTitle = state?.searchStaticContent?.noResultLabel?.replace(
      '${QUERY}',
      removeHtmlTag(state.queryParm)
    );
    returnValue = (
        <span className='ob-search-block__no-results-title' dangerouslySetInnerHTML={{__html:sanitize(noResultsTitle)}} />
    );
  } else if (state?.hitsResponse) {
    returnValue = (
      <>
        <CustomRefinementList
          attribute='pageType'
          state={state}
          dispatch={dispatch}
        />
        <CustomStateResults state={state} dispatch={dispatch} />
        {state?.hitsResponse?.map((hit, index) => (
          <Hit key={index} hit={hit}/>
        ))}

        <Pagination state={state} dispatch={dispatch} />
      </>
    );
  }
  return returnValue;
};

const HitsHOC = connectHits(Hits);

export default React.memo(HitsHOC);
